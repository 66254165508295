import React from 'react';
import {BrowserRouter} from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import LandingPage from './LandingPage';
import posthog from 'posthog-js'

// US server
posthog.init('phc_lI6L9atJA3N8qIe1t8P7xxNYBYuFQhcVzcMwjfUa1BE',
  {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'always',
  }
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LandingPage />
    </BrowserRouter>
  </React.StrictMode>
);
