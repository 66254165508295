import React from 'react';
import Grid from '@mui/material/Grid';

function getNewStyle() {
  return {
    paddingLeft: window.innerWidth > 800 ? 50 : 0,
  }
}

export default function Features() {
  return (<div>
    {/* Why Atium Sports */}
    <h3 style={{textAlign: 'center', textDecoration: 'underline', fontSize: '25px'}}>Why Atium Sports</h3>
    <div className="dfcolumn bgPurple">
      <Grid container spacing={2} style={{padding: 50, borderBottom: '1px solid black'}}>
        <Grid item xs={12} md={12} lg={2} className='df'>
          <h5 className='ma pz featureHeading'>Multi-Role &  <br /> Multi-Sports &  <br /> Multi-Platform</h5>
        </Grid>
        <Grid item xs={12} md={12} lg={10} style={getNewStyle()}>
          <p className='pz mz featurePara'>ATIUM Sports is available on all platforms specially curated for each. (IOS, Android and Web browser)</p>
          <p className='pz mz featurePara'>All Roles One Platform : Athletes, Coaches, Executives, Admin, Medical Staff, Physiotherapists, Psychologists, Sports Scientists. All Sports One Platform: 80+ sports support.</p>
          <p className='pz mz featurePara'>ATIUM Sports boasts a flexible access control system that adapts to user roles and attributes, granting customised platform capabilities to access the system, ensuring that users have tailored and secure access to platform features. Depending on their role and attributes, users will have a unique set of permissions and functionalities. This dynamic configuration allows for seamless customisation to meet your specific requirements in security and fucntionality.</p>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{padding: 50, borderBottom: '1px solid black'}}>
        <Grid item xs={12} md={12} lg={2} className='df'>
          <h5 className='ma pz featureHeading'>Customizations</h5>
        </Grid>
        <Grid item xs={12} md={12} lg={10} style={getNewStyle()}>
          <p className='pz mz featurePara'>The ATIUM platform offers a versatile language configuration, supporting multiple Indian and international languages to cater to a diverse user base.</p>
          <p className='pz mz featurePara'>The Platform also enables coaches to customise and add their own list of exercises, trainings, workouts and forms, for training, monitoring, and assessments.</p>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{padding: 50, borderBottom: '1px solid black'}}>
        <Grid item xs={12} md={12} lg={2} className='df'>
          <h5 className='ma pz featureHeading'>Data Analysis</h5>
        </Grid>
        <Grid item xs={12} md={12} lg={10} style={getNewStyle()}>
          <p className='pz mz featurePara'>Over 60 specialised, sports science-driven pre-existing dashboards designed for specific needs and purposes.</p>
          <p className='pz mz featurePara'>Bulk upload your current training data to access a unified view of athlete test results and performance metrics for comprehensive analysis.</p>
          <p className='pz mz featurePara'>Going beyond pre-built dashboards, use our custom dashboard builder which brings powerful analytical capabilities to leverage the wealth of existing data for enhanced insights and decision-making.</p>
          <p className='pz mz featurePara'>Get on the spot analysis of data using our Gen-Ai assistant or simply ask any query related to fitness/sports or nutrition.</p>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{padding: 50, borderBottom: '1px solid black'}}>
        <Grid item xs={12} md={12} lg={2} className='df'>
          <h5 className='ma pz featureHeading'>Dravid AI</h5>
        </Grid>
        <Grid item xs={12} md={12} lg={10} style={getNewStyle()}>
          <p className='pz mz featurePara'>Our Nexgen AI Assistant powered by <a href='https://www.anthropic.com/news/claude-3-5-sonnet' target="_blank" rel="noreferrer">Anthropic Claude 3.5</a> is designed to guide and support coaches and athletes in achieving their goals.</p>
          <p className='pz mz featurePara'>Dravid AI embodies the relentless pursuit of excellence, the name inspired by India's legendary cricketer and coach, Rahul Dravid. The name also pays homage to the rich cultural heritage of the Dravidian legacy, representing strength, resilience, and a deep connection to our roots.</p>
          <p className='pz mz featurePara'>Currently, Dravid AI 1.2 supports answering querries on fitness and sports science. Dravid AI can also be asked to interpret or retireve the data collected/stored(subject to access restrictions) on our platform.</p>
          <p className='pz mz featurePara'>The following versions will support advanced insights, agent support and real-time data analysis.</p>
        </Grid>
      </Grid>
    </div>
    {/* Planning */}
    <h3 style={{textAlign: 'center', textDecoration: 'underline', fontSize: '25px'}}>Planning</h3>
    <div className="dfcolumn bgPurple">
      <Grid container spacing={2} style={{padding: 50, borderBottom: '1px solid black'}}>
        <Grid item xs={12} md={12} lg={2} className='df'>
          <h5 className='ma  featureHeading' style={{textAlign: 'start'}}>Calendar</h5>
        </Grid>
        <Grid item xs={12} md={12} lg={10} style={getNewStyle()}>
          <p className='pz mz featurePara'>Harness the power of our feature-rich calendar, complete with a range of pre-designed templates for diverse tasks and events. Effortlessly manage events, appointments, training schedules, and mark off days with seamless efficiency.</p>
          <p className='pz mz featurePara'>Access a library of pre-designed templates for scheduling across performance, medical and administrative modules. From assessments, monitoring to training sessions and check-in appointments, streamline your planning process with ease. These events will be prominently displayed in both the Athlete's and collaborators' calendars, as well as in their daily plans.</p>
          <p className='pz mz featurePara'>Collaborators (physiotherapists, medics etc..) can view and edit athlete's training plans to truly foster a co-operative collaboration in the organisation. History of such edits are maintained for transparency and accountability.</p>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{padding: 50, borderBottom: '1px solid black'}}>
        <Grid item xs={12} md={12} lg={2} className='df'>
          <h5 className='ma pz featureHeading'>Exercises, Trainings, Workouts & Programs</h5>
        </Grid>
        <Grid item xs={12} md={12} lg={10} style={getNewStyle()}>
          <p className='pz mz featurePara'>Select from a curated catalog of scientifically validated and trusted exercises, trainings and workouts.</p>
          <p className='pz mz featurePara'>Personalise your training program by effortlessly storing and integrating custom exercises, trainings and workouts. Seamlessly upload and securely store your personalised exercises within the ATIUM platform.</p>
          <p className='pz mz featurePara'>Use our simplified training program builder to plan for weeks/months or the entire year. The programs are split in to individual sessions and linked instantly with the calendar for ease of use.</p>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{padding: 50, borderBottom: '1px solid black'}}>
        <Grid item xs={12} md={12} lg={2} className='df'>
          <h5 className='ma pz featureHeading'>Assessments & Performance</h5>
        </Grid>
        <Grid item xs={12} md={12} lg={10} style={getNewStyle()}>
          <p className='pz mz featurePara'>Select from a curated catalog of scientifically validated and trusted assessments and monitoring tools. Find role specific assessments & forms in our comprehensive sports science library module, and easily deploy them through our calendar.</p>
          <p className='pz mz featurePara'>Craft a comprehensive testing battery, and set norms and targets tailored to your athlete's requirement. Develop a personalised list of tests that athletes will complete over a designated period, forming a crucial component of their periodic evaluation.</p>
          <p className='pz mz featurePara'>Track performance and data updates through live dashboards linked to your training session directly from your calendar.</p>
          <p className='pz mz featurePara'>All data gathered on our platform  undergo thorough analysis, and are presented in graphical format for your use. The information includes statistical analysis, variability details, and trend highlights for a complete overview.</p>
          <p className='pz mz featurePara'>Exercise full control over data selection, visualisation choices, layout, filters, standards and other interactive elements for a truly personalised experience.</p>
        </Grid>
      </Grid>
    </div>
    {/* Monitoring */}
    <h3 style={{textAlign: 'center', textDecoration: 'underline', fontSize: 25}}>Monitoring</h3>
    <div className="dfcolumn bgPurple">
      <Grid container spacing={2} style={{padding: 50, borderBottom: '1px solid black'}}>
        <Grid item xs={12} md={12} lg={2} className='df'>
          <h5 className='ma  featureHeading' style={{textAlign: 'start'}}>Wellness & Readiness Index</h5>
        </Grid>
        <Grid item xs={12} md={12} lg={10} style={getNewStyle()}>
          <p className='pz mz featurePara'>Utilise user-friendly questionnaires to track athlete well-being. Gain insights through wellness scores and dedicated dashboards for a comprehensive view of their overall health.</p>
          <p className='pz mz featurePara'>Incorporating Hooper Index's four elements, plus vital metrics such as sleep duration, stress levels, motivation, and hunger for a holistic assessment of athlete well-being.</p>
          <p className='pz mz featurePara'>Get Instant access to an athlete's training readiness index which is calculated from their entire history of wellness and internal load data and is curated to individual athletes.</p>
          <p className='pz mz featurePara'>Coaches get a single pane view to see the current wellness and readiness scores of all the athletes in their respective teams managed by them.</p>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{padding: 50, borderBottom: '1px solid black'}}>
        <Grid item xs={12} md={12} lg={2} className='df'>
          <h5 className='ma pz featureHeading'>Internal Load</h5>
        </Grid>
        <Grid item xs={12} md={12} lg={10} style={getNewStyle()}>
          <p className='pz mz featurePara'>Precise tracking and monitoring of training load. Get precise internal load quantification data by either utilising Foster's Internal Load methodology which integrates RPE (Rate of Perceived Exertion) and exercise duration or using a hardware to bring in internal load data measures using TRIMP method.</p>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{padding: 50, borderBottom: '1px solid black'}}>
        <Grid item xs={12} md={12} lg={2} className='df'>
          <h5 className='ma pz featureHeading'>External Load</h5>
        </Grid>
        <Grid item xs={12} md={12} lg={10} style={getNewStyle()}>
          <p className='pz mz featurePara'>Seamlessly track gym, distance load and other external loads directly from the calendar interface. Load entry widgets on athlete's page/app lets them know and track their progress, and motivates them to complete their sessions.</p>
          <p className='pz mz featurePara'>Access training load data through visually- rich, pre-designed live dashboards for comprehensive analysis which can be performed while the session is in progress.</p>
        </Grid>
      </Grid>
    </div>
    {/* Health Mangement */}
    <h3 style={{textAlign: 'center', textDecoration: 'underline', fontSize: 25}}>Health Management</h3>
    <div className="dfcolumn bgPurple">
      <Grid container spacing={2} style={{padding: 50, borderBottom: '1px solid black'}}>
        <Grid item xs={12} md={12} lg={2} className='df'>
          <h5 className='ma  featureHeading' style={{textAlign: 'start'}}>Injury Management</h5>
        </Grid>
        <Grid item xs={12} md={12} lg={10} style={getNewStyle()}>
          <p className='pz mz featurePara'>Streamlined Injury and Illness Reporting for Athletes, Coaches, and Medical Staff.</p>
          <p className='pz mz featurePara'>Users can register various health issues, not limited to predefined overuse injuries in specific anatomical areas. Coaches and medical staff receive immediate notifications for prompt attention and action.</p>
          <p className='pz mz featurePara'>Streamlined period reporting for female athletes: Report period dates, symptoms, flow type, mood, and pain levels effortlessly and periodically.</p>
          <p className='pz mz featurePara'>Track athlete health alternatively through the scientifically validated OSTRC H2 Questionnaire available for athletes to complete every Monday.</p>
          <p className='pz mz featurePara'>Physiotherapists can use existing forms for MSK, PCMA, Impingement, Instability, postural etc to perform periodic assessments.</p>
          <p className='pz mz featurePara'>Psychologists get access to ACSI, CSAI and OMSAT-3 questionnaires out of the box. You can also custom build forms for your use.</p>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{padding: 50, borderBottom: '1px solid black'}}>
        <Grid item xs={12} md={12} lg={2} className='df'>
          <h5 className='ma pz featureHeading'>Follow Up & Return To Play</h5>
        </Grid>
        <Grid item xs={12} md={12} lg={10} style={getNewStyle()}>
          <p className='pz mz featurePara'>Medical Professionals can make use of the automated workflows for injury/illness tracking. Take SOAP notes , set outcome measures (1000+ already on the platform) for each appointment directly on the platform. Review players diagnosis and treatment history and share treatment notes to coaches and other relevant parties. Make use of the complete collection of diagnosis (7000+ OSIICS v13 & ICD) or set your own custom preliminary diagnosis on the platform. Filter diagnosis based on Area/Tissue or pathology to instantly find the diagnosis you are looking for. Set Expected RTP to help coaches and collaborators make informed decisions. Physiotherapists can seamlessly work together with medical professionals and coaches to alter athlete's training program and session with least interference.</p>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{padding: 50, borderBottom: '1px solid black'}}>
        <Grid item xs={12} md={12} lg={2} className='df'>
          <h5 className='ma pz featureHeading'>Data Analysis & Reporting</h5>
        </Grid>
        <Grid item xs={12} md={12} lg={10} style={getNewStyle()}>
          <p className='pz mz featurePara'>Comprehensive reports and analysis are made available through our pre-built OSTRC-H2, Injury Management and Covid-19 dashboards. Use custom dashboard feature to correlate health data with performance data to make post training analysis. Download and share reports directly from the dashboards to relevant personel.</p>
          <p className='pz mz featurePara'>Physiological assessment results (both current and historical) can seamless be reviewed and a final report can be shared with collborators.</p>
          <p className='pz mz featurePara'>Comprehensive Period Dashboard: A dedicated dashboard offers a 6-month overview, highlighting past periods and predicting the next. It provides detailed current cycle information (reported symptoms, start and end dates, and current phase). Tailored recommendations for nutrition, training, and recovery are offered based on the athlete's current phase.</p>
        </Grid>
      </Grid>
    </div>
    {/* Administration */}
    <h3 style={{textAlign: 'center', textDecoration: 'underline', fontSize: 25}}>Administration</h3>
    <div className="dfcolumn bgPurple">
      <Grid container spacing={2} style={{padding: 50, borderBottom: '1px solid black'}}>
        <Grid item xs={12} md={12} lg={2} className='df'>
          <h5 className='ma  featureHeading' style={{textAlign: 'start'}}>Organisation</h5>
        </Grid>
        <Grid item xs={12} md={12} lg={10} style={getNewStyle()}>
          <p className='pz mz featurePara'>Streamline access control and build organisation charts through our organisation module.</p>
          <p className='pz mz featurePara'>Set up departments and visualise auth control for your entrire organisation.</p>
          <p className='pz mz featurePara'>Curated authorisation stack built on a robust Role base authentication system brings a level of customisability and security never before seen in athlete management systems.</p>
          <p className='pz mz featurePara'>ATIUM's attendance & compliance module offers a user- friendly overview of attendance trends, compliance metrics and reports. Generate and share daily reports summarising attendance percentages etc.</p>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{padding: 50, borderBottom: '1px solid black'}}>
        <Grid item xs={12} md={12} lg={2} className='df'>
          <h5 className='ma pz featureHeading'>Users & Team Management</h5>
        </Grid>
        <Grid item xs={12} md={12} lg={10} style={getNewStyle()}>
          <p className='pz mz featurePara'>Seamless User and Team management. Manage Team access for support staff and executives easily.</p>
          <p className='pz mz featurePara'>Track and manage process compliance and usage metrics through the admin module.</p>
          <p className='pz mz featurePara'>Calendar, dashboards can be viewed at both user and team levels. Create Training programs for individuals or Teams and easily track thier training progress.</p>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{padding: 50, borderBottom: '1px solid black'}}>
        <Grid item xs={12} md={12} lg={2} className='df'>
          <h5 className='ma pz featureHeading'>Customisations</h5>
        </Grid>
        <Grid item xs={12} md={12} lg={10} style={getNewStyle()}>
          <p className='pz mz featurePara'>Customise ATIUM Sports experience by optionally turning off/on features, monitors and default settings which you may or may not require.</p>
          <p className='pz mz featurePara'>Customise dashboards access by roles, set sports managed in your organisations and much more. </p>
          <p className='pz mz featurePara'>Need additional customisations for your organisation ? reach out to our customer success team and get prompt and immediate help on your requirements. </p>
          <p className='pz mz featurePara'>Seamless hardware integration: Effortlessly incorporate compatible devices for enhanced functionality and performance and automated data collection. Reach out to us if your hardware is not already available for integration.  </p>
        </Grid>
      </Grid>
    </div>
    {/* Data Privacy */}
    <h3 style={{textAlign: 'center', textDecoration: 'underline', fontSize: 25}}>Data Privacy & Security</h3>
    <div className="dfcolumn bgPurple">
      <Grid container spacing={2} style={{padding: 50, borderBottom: '1px solid black'}}>
        <Grid item xs={12} md={12} lg={2} className='df'>
          <h5 className='ma  featureHeading' style={{textAlign: 'start'}}>Availability & Performance</h5>
        </Grid>
        <Grid item xs={12} md={12} lg={10} style={getNewStyle()}>
          <p className='pz mz featurePara'>Continuous uptime: Ensuring uninterrupted access and performance. Enjoy uninterrupted access to ATIUM, with minimal downtime, only for essential system maintenance or updates on rare occasions.</p>
          <p className='pz mz featurePara'>Our performance oriented custom build backend using cutting edge technology delivers blazingly fast sub second responses to keep your app engagement as smooth as possible.</p>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{padding: 50, borderBottom: '1px solid black'}}>
        <Grid item xs={12} md={12} lg={2} className='df'>
          <h5 className='ma pz featureHeading'>Data Security</h5>
        </Grid>
        <Grid item xs={12} md={12} lg={10} style={getNewStyle()}>
          <p className='pz mz featurePara'>
            Your Information is safeguarded with safe encryption and access controls.
            Your information is hosted on Amazon's Secure Cloud Environment, subject to continuous audits and accreditation from diverse geographical and vertical authorities.
            Additionally, a multi-layered authorisation architecture (RBAC+ABAC) ensures unwavering protection against unauthorised data access. Also the data stored on cloud is safeguarded with strong cryptographic Keys,
            providing an extra layer of protection in the unlikely event of data leakage.
          </p>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{padding: 50, borderBottom: '1px solid black'}}>
        <Grid item xs={12} md={12} lg={2} className='df'>
          <h5 className='ma pz featureHeading'>Data Privacy</h5>
        </Grid>
        <Grid item xs={12} md={12} lg={10} style={getNewStyle()}>
          <p className='pz mz featurePara'>Compliance with data privacy regulations:</p>
          <p className='pz mz featurePara'>Our system is rigorously designed from the ground up to implement and adhere to stringent data privacy standards like GDPR, CCPA & DPDP. Users retain full control over their private information (PID) stored in system. All data collected on the system is decoupled with user's PID data to ensure maximum privacy. </p>
          <p className='pz mz featurePara'>For additional details, refer to ATIUM Sport's Privacy Policy link in the footer.</p>
        </Grid>
      </Grid>
    </div>
  </div>)
}
